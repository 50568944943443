<template>
  <div class="data-privacy">
    <div class="data-privacy__title">Datenschutzerklärung</div>
    <div class="data-privacy__content">
      <p>
        <b>Allgemeines</b><br /><b>1.1 Was sind personenbezogene Daten<br /></b
        >Personenbezogene Daten sind Angaben, die die Identität des Nutzers
        offenlegen oder offenlegen können. Wir halten uns an den Grundsatz der
        Datenvermeidung. Es wird soweit wie möglich auf die Erhebung von
        personenbezogenen Daten verzichtet.
      </p>
      <p>
        <b>1.2 Umgang mit personenbezogenen Daten</b><br />Personenbezogene
        Daten dienen ausschließlich der Vertragsbegründung, inhaltlichen
        Ausgestaltung, Durchführung oder Abwicklung des Vertragsverhältnisses
        (Art. 6 I S. 1 b DSGVO).
      </p>
      <p>
        Darüber hinaus werden personenbezogene Daten nur verarbeitet, soweit wir
        hierzu Ihre Einwilligung erhalten haben (Art. 6 I S. 1 a DSGVO) bzw. es
        sich um Daten handelt, deren Verarbeitung für unsere berechtigten
        Interessen erforderlich ist und soweit die Abwägung ergibt, dass keine
        überwiegenden Interessen, Grundrechte oder Grundfreiheiten Ihrerseits
        entgegenstehen (Art. 6 I S. 1 f DSGVO).
      </p>
      <p>
        Wir können zur Verarbeitung Ihrer personenbezogenen Daten
        Auftragsverarbeiter verwenden, werden die personenbezogenen Daten
        darüber hinaus aber grundsätzlich nicht an Dritte weitergeben.
      </p>
      <p>
        Zur Abwicklung von Zahlungen werden die hierfür erforderlichen
        Zahlungsdaten an das mit der Zahlung beauftragte Kreditinstitut und ggf.
        den beauftragten und gewählten Zahlungsdienstleister weitergegeben.
      </p>
      <p>
        Die Verarbeitung Ihrer personenbezogenen Daten erfolgt ausschließlich
        innerhalb der EU, soweit nachfolgend nicht etwas Abweichendes dargelegt
        ist.
      </p>
      <p>
        <b>1.3 Nutzungsdaten</b><br />Beim Besuch der Webseite werden allgemeine
        technische Informationen erhoben. Dies sind die verwendete IP-Adresse,
        Uhrzeit, Dauer des Besuchs, Browsertyp und ggf. die Herkunftsseite.
        Diese Nutzungsdaten werden technisch bedingt in einem Logfile
        registriert und können zum Zwecke der Statistikauswertung dieser
        Webseite benutzt und gespeichert werden. Eine Verknüpfung dieser
        Nutzungsdaten mit Ihren weiteren personenbezogenen Daten findet nicht
        statt.
      </p>
      <p>
        <b>1.4 Registrierungsdaten</b><br />Für die umfassende Nutzung der
        Funktionen unserer Webseite ist eine Registrierung erforderlich. Die
        Registrierungsdaten werden durch Ihre entsprechenden Eingaben erhoben
        und für den konkret angegebenen Zweck gemäß Ihrer Einwilligung verwendet
        (Art. 6 I S. 1 a DSGVO).
      </p>
      <p>
        <b>1.5 Dauer der Speicherung<br /></b>Wir speichern Ihre
        personenbezogenen Daten nach der Beendigung des Zwecks, für welchen die
        Daten erhoben wurden, nur solange, wie dies auf Grund der gesetzlichen
        (insbesondere steuerrechtlichen) Vorschriften erforderlich ist.
      </p>
      <p><b>2. Ihre Rechte</b></p>
      <p>
        <b>2.1 Auskunft<br /></b>Sie können von uns eine Auskunft darüber
        verlangen, ob wir personenbezogene Daten von Ihnen verarbeiten und
        soweit dies der Fall ist haben Sie ein Recht auf Auskunft über diese
        personenbezogenen Daten und auf die in Art. 15 DSGVO genannten weiteren
        Informationen.
      </p>
      <p>
        <b>2.2 Recht auf Berichtigung<br /></b>Sie haben das Recht auf
        Berichtigung der Sie betreffenden unrichtigen personenbezogenen Daten
        und können gemäß Art. 16 DSGVO die Vervollständigung unvollständiger
        personenbezogener Daten verlangen.
      </p>
      <p>
        <b>2.3 Recht auf Löschung<br /></b>Sie haben das Recht von uns zu
        verlangen, dass die Sie betreffenden personenbezogenen Daten
        unverzüglich gelöscht werden. Wir sind verpflichtet diese unverzüglich
        zu löschen, insbesondere sofern einer der folgenden Gründe zutrifft:
      </p>
      <p>
        Ihre personenbezogenen Daten sind für die Zwecke, für die sie erhoben
        oder auf sonstige Weise verarbeitet wurden, nicht mehr notwendig.<br />Sie
        widerrufen ihre Einwilligung, auf die sich die Verarbeitung Ihrer Daten
        stützte, und es fehlt an einer anderweitigen Rechtsgrundlage für die
        Verarbeitung.<br />Ihre Daten wurden unrechtmäßig verarbeitet.<br />Das
        Recht auf Löschung besteht nicht, soweit Ihre personenbezogenen Daten
        zur Geltendmachung, Ausübung oder Verteidigung unserer Rechtsansprüche
        erforderlich sind.
      </p>
      <p>
        <b>2.4 Recht auf Einschränkung der Verarbeitung<br /></b>Sie haben das
        Recht von uns die Einschränkung der Verarbeitung Ihrer personenbezogenen
        Daten zu verlangen, wenn
      </p>
      <p>
        Sie die Richtigkeit der Daten bestreiten und wir daher die Richtigkeit
        überprüfen,<br />die Verarbeitung unrechtmäßig ist und Sie die Löschung
        ablehnen und stattdessen die Einschränkung der Nutzung verlangen<br />wir
        die Daten nicht länger benötigen, Sie diese jedoch zur Geltendmachung,
        Ausübung oder Verteidigung von Rechtsansprüchen benötigen,<br />Sie
        Widerspruch gegen die Verarbeitung Ihrer Daten eingelegt haben, und noch
        nicht feststeht, ob unsere berechtigten Gründe gegenüber Ihren Gründen
        überwiegen.
      </p>
      <p>
        <b>2.5 Recht auf Datenübertragbarkeit<br /></b>Sie haben das Recht die
        Sie betreffenden personenbezogenen Daten, die Sie uns bereitgestellt
        haben, in einem strukturierten, gängigen und maschinenlesbaren Format zu
        erhalten und Sie haben das Recht, diese Daten einem anderen
        Verantwortlichen ohne Behinderung durch uns zu übermitteln, sofern die
        Verarbeitung auf einer Einwilligung oder einem Vertrag beruht und die
        Verarbeitung bei uns mithilfe automatisierter Verfahren erfolgt.
      </p>
      <p>
        <b>2.6 Widerrufsrecht<br /></b>Soweit die Verarbeitung Ihrer
        personenbezogenen Daten auf einer Einwilligung beruht, haben Sie das
        Recht diese Einwilligung jederzeit zu widerrufen.
      </p>
      <p>
        <b>2.7 Allgemeines und Beschwerderecht<br /></b>Die Ausübung Ihrer
        vorstehenden Rechte ist für Sie grundsätzlich kostenlos. Sie haben das
        Recht sich bei Beschwerden direkt an die für uns zuständige
        Aufsichtsbehörde, den Landesdatenschutzbeauftragen, zu wenden.
      </p>
      <p><b>3. Datensicherheit</b></p>
      <p>
        <b>3.1 Datensicherheit<br /></b>Sämtliche Daten auf unserer Website
        werden durch technische und organisatorische Maßnahmen gegen Verlust,
        Zerstörung, Zugriff, Veränderung und Verbreitung gesichert.
      </p>
      <p>
        <b>3.2 Sessions und Cookies<br /></b>Zur Bedienung des Internetauftritts
        können wir Cookies oder serverseitige Sessions einsetzen, in denen Daten
        gespeichert werden können. Cookies sind Dateien, die von einer Webseite
        auf Ihrer Festplatte abgelegt werden, um diesen Computer beim nächsten
        Webseitenbesuch automatisch wieder zu erkennen und damit die Nutzung der
        Webseite an Sie anpassen zu können. Einige der verwendeten Cookies
        werden nach dem Ende der Browser-Sitzung wieder gelöscht. Dabei handelt
        es sich um sogenannte Sitzungs-Cookies. Andere Cookies verbleiben auf
        Ihrem Endgerät und ermöglichen die Wiedererkennung des Browsers bei
        einem späteren Besuch unserer Website (dauerhafte Cookies). Sie können
        Ihren Browser so einstellen, dass Sie über das Setzen von Cookies
        informiert werden und einzeln über deren Annahme entscheiden oder die
        Annahme von Cookies für bestimmte Fälle oder generell ausschließen.
        Bitte beachten Sie dabei, dass Sie möglicherweise einige Funktionen
        dieser Website nicht nutzen können, wenn die Cookies deaktiviert sind.
        Wir stellen sicher, dass keine personenbezogenen Daten aus Sessions oder
        durch Cookies übernommen werden und Cookies nur eingesetzt werden,
        sofern dies für die Website technisch erforderlich ist. Somit ergibt die
        Abwägung, dass keine überwiegenden Interessen Ihrerseits entgegenstehen
        (Art. 6 I S. 1 f DSGVO).
      </p>
      <p><b>4. Newsletter</b></p>
      <p>
        Wenn Sie sich zu unserem Newsletter anmelden, verwenden wir die hierfür
        erforderlichen oder gesondert von Ihnen mitgeteilten Daten, um Ihnen
        regelmäßig unseren E-Mail-Newsletter zuzusenden. Die Abmeldung vom
        Newsletter ist jederzeit möglich und kann entweder durch eine Nachricht
        an uns über die im Impressum angegebenen Kontaktmöglichkeiten oder über
        den dafür vorgesehenen Link im Newsletter erfolgen.
      </p>
      <p>
        <b>5. Präsenz auf Social Media Plattformen</b>
      </p>
      <p>
        Wir nutzen nachfolgende Social Media Plattformen zur
        Unternehmensdarstellung und Kommunikation (auf die nachfolgend
        verlinkten Datenschutzerklärungen und OptOut-Möglichkeiten wird
        ausdrücklich Bezug genommen).
      </p>
      <p>
        Facebook (Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal
        Harbour, Dublin 2, Irland)<br />Datenschutzerklärung:
        https://www.facebook.com/about/privacy/<br />Opt-Out:
        https://www.facebook.com/settings?tab=ads alternativ
        http://www.youronlinechoices.com
      </p>
      <p>
        Instagram (Instagram Inc., 1601 Willow Road, Menlo Park, CA, 94025,
        USA)<br />Datenschutzerklärung und Opt-Out:
        http://instagram.com/about/legal/privacy/.
      </p>
      <p>
        LinkedIn (LinkedIn Ireland Unlimited Company Wilton Place, Dublin 2,
        Irland)<br />Datenschutzerklärung
        https://www.linkedin.com/legal/privacy-policy<br />Opt-Out:
        https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out
      </p>
      <p>
        Diese Social-Media-Plattformen können ggf. personenbezogene Daten
        außerhalb der EU verarbeiten, wir verweisen insoweit auf die
        vorstehenden Datenschutzerklärungen der Social Media Plattformen.<br />Die
        jeweiligen Social-Media-Plattformen können ggf. aus Ihrem
        Nutzungsverhalten und den sich hieraus ergebenden Interessen uns
        Aktionen Ihrerseits Nutzungsprofile erstellen und Cookies auf Ihrem
        Rechner speichern, in denen Ihr Nutzungsverhalten gespeichert ist. Wenn
        Sie auf der jeweiligen Social-Media-Plattform einen Account besitzen und
        eingeloggt sind, kann Ihr Nutzungsverhalten sogar geräteunabhängig
        gespeichert werden. Ihr Nutzungsprofil kann verwendet werden, um z.B.
        Werbeanzeigen zu platzieren, die mutmaßlich Ihren Interessen
        entsprechen.
      </p>
      <p>
        Wir verarbeiten die personenbezogenen Daten ausschließlich zur
        Kommunikation mit Ihnen über die von Ihnen gewählte
        Social-Media-Plattform sowie zur Optimierung unserer Onlinepräsenz und
        achten darauf, dass hier keine Interessen Ihrerseits betroffen sind,
        welche dieses berechtigte Interesse unsererseits überwiegen (Art. 6 I S.
        1 f DSGVO). Soweit Sie bereits dem jeweiligen Betreiber der
        Social-Media-Plattform eine wirksame Einwilligung zur entsprechenden
        Datenverarbeitung gegeben haben, erfolgt die Verarbeitung Ihrer
        personenbezogenen Daten auch auf Basis dieser Einwilligung (Art. 6 I S.
        1 a DSGVO).
      </p>
      <p><b>Dienste von Drittanbietern</b></p>
      <p>
        <b>6.1 Google Analytics<br /></b>Diese Website benutzt Google Analytics,
        einen Webanalysedienst von Google, betrieben von der Google Ireland
        Limited, Gordon House, Barrow Street, Dublin 4, Irland („Google“) und
        erhebt und speichert über diesen Webanalysedienst Daten, aus denen unter
        Verwendung von Pseudonymen Nutzungsprofile erstellt werden. Die so
        erstellten Nutzungsprofile dienen der Auswertung des Besucherverhaltens
        um das auf dieser Website dargestellte Angebot bedarfsgerecht zu
        gestalten und zu verbessern. Google Analytics verwendet sog. „Cookies“,
        kleine Textdateien, die auf Ihrem Computer gespeichert werden und die
        eine Analyse der Benutzung der Website durch Sie ermöglichen. Die durch
        den Cookie erzeugten Informationen über Ihre Benutzung dieser Website
        werden in der Regel an einen Server von Google in den USA übertragen und
        dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf
        dieser Webseite, wird Ihre IP-Adresse von Google jedoch innerhalb von
        Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten
        des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur
        in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google
        in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser
        Website wird Google diese Informationen benutzen, um Ihre Nutzung der
        Website auszuwerten, um Reports über die Websiteaktivitäten
        zusammenzustellen und um weitere mit der Websitenutzung und der
        Internetnutzung verbundene Dienstleistungen gegenüber dem
        Websitebetreiber zu erbringen. Die im Rahmen von Google Analytics von
        Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten von
        Google zusammengeführt. Auch die unter einem Pseudonym geführten
        Nutzungsprofile werden ohne ausdrückliche und gesondert erklärte
        Einwilligung des Nutzers nicht mit den personenbezogenen Daten über den
        Nutzer zusammengeführt. Somit ergibt die Abwägung, dass keine
        überwiegenden Interessen Ihrerseits entgegenstehen (Art. 6 I S. 1 f
        DSGVO). Sie können die Speicherung der Cookies durch eine entsprechende
        Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
        darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
        Funktionen dieser Website vollumfänglich werden nutzen können. Sie
        können darüber hinaus die Erfassung der durch das Cookie erzeugten und
        auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an
        Google sowie die Verarbeitung dieser Daten durch Google verhindern,
        indem sie das unter dem folgenden Link
        (http://tools.google.com/dlpage/gaoptout?hl=de) verfügbare
        Browser-Plugin herunterladen und installieren.<br />Sie können die
        Erfassung Ihrer Daten durch Google Analytics darüber hinaus verhindern,
        indem Sie auf folgenden Link klicken. Es wird ein Opt-Out-Cookie
        gesetzt, der die Erfassung Ihrer Daten bei zukünftigen Besuchen dieser
        Website verhindert: Google Analytics deaktivieren.Die
        Datenschutzhinweise von Google können Sie auf
        http://www.google.de/intl/de/policies/privacy/ einsehen. Nähere
        Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter
        http://www.google.com/analytics/terms/de.html bzw. unter
        http://www.google.com/intl/de/analytics/privacyoverview.html. Wir weisen
        Sie darauf hin, dass auf dieser Webseite Google Analytics um den Code
        „anonymizeIp“ erweitert wurde, um eine anonymisierte Erfassung von
        IP-Adressen (sog. IP-Masking) zu gewährleisten.
      </p>
      <p>
        <b>6.2 Social-Media-Links und Social-Sharing<br /></b>Wir haben bei den
        über Links von dieser Website zu erreichenden Drittanbietern eigene
        Social-Media-Seiten. Durch die Nutzung der Links gelangen Sie auf die
        jeweiligen Internetseiten der Drittanbieter (z.B. Facebook, Twitter,
        Google+) und können unsere Inhalte auch teilen. Hierbei findet durch den
        Aufruf unserer Website kein Datentransfer statt. Wir empfehlen zur
        Vermeidung einer unnötigen Datenweitergabe vor der Nutzung eines
        entsprechenden Links sich selbst bei dem jeweiligen Drittanbieter
        auszuloggen, damit nicht schon durch die Verwendung des Links u.U.
        Nutzungsprofile durch den Drittanbieter erstellt werden können.
      </p>
      <p>
        <b>6.3 Verwendung von Vimeo<br /></b>Diese Webseite und die integrierten
        Angebote enthalten sogenannte Einbettungen von Videos auf Vimeo. Diese
        ermöglichen die Verbindung zu Vimeo und den dort hinterlegten Videos.
        Vimeo wird betrieben von der Vimeo, Inc. 555 West 18th Street, New York,
        New York 10011. Hier sind keine Interessen der Nutzer betroffen, welche
        diese technische Notwendigkeit der Einbindung der Videos überwiegen
        (Art. 6 I S. 1 f DSGVO). Zweck und Umfang der Datenerhebung und
        Datennutzung durch Vimeo sowie Ihre Rechte und Einstellungsmöglichkeiten
        zum Schutz als Kunde von Vimeo entnehmen Sie bitte den
        Datenschutzhinweisen von Vimeo. Diese finden Sie unter:
        https://vimeo.com/privacy
      </p>
      <p>
        <b>6.4 Verwendung von MailChimp<br /></b>Die Abwicklung unserer
        E-Mail-Kommunikation erfolgt mittels „MailChimp“, einer Plattform des
        US-Anbieters Rocket Science Group, LLC, 675 Ponce De Leon Ave NE #5000,
        Atlanta, GA 30308, USA. Hierbei werden Ihre E-Mail-Adresse und auch Ihre
        weiteren, im Rahmen dieser Hinweise beschriebenen Daten, auf den Servern
        von MailChimp in den USA gespeichert. MailChimp verwendet diese
        Informationen zum Versand und zur Auswertung der E-Mails in unserem
        Auftrag. Des Weiteren kann MailChimp nach eigenen Informationen diese
        Daten zur Optimierung oder Verbesserung der eigenen Services nutzen,
        z.B. zur technischen Optimierung des Versandes und der Darstellung der
        Newsletter oder für wirtschaftliche Zwecke, um zu bestimmen aus welchen
        Ländern die Empfänger kommen. MailChimp nutzt die Daten unserer
        Mailempfänger nicht, um diese selbst anzuschreiben oder an Dritte
        weiterzugeben. MailChimp ist unter dem US-EU-Datenschutzabkommen
        „Privacy Shield“ zertifiziert und verpflichtet sich damit die
        EU-Datenschutzvorgaben einzuhalten. Des Weiteren haben wir mit MailChimp
        ein „Data-Processing-Agreement“ abgeschlossen. Dabei handelt es sich um
        einen Vertrag, in dem sich MailChimp dazu verpflichtet, die Daten
        unserer Nutzer zu schützen, entsprechend dessen Datenschutzbestimmungen
        in unserem Auftrag zu verarbeiten und insbesondere nicht an Dritte
        weiter zu geben. Somit ergibt die Abwägung, dass keine überwiegenden
        Interessen Ihrerseits entgegenstehen (Art. 6 I S. 1 f DSGVO).Die
        Datenschutzbestimmungen von MailChimp können Sie unter
        https://mailchimp.com/legal/privacy/ einsehen.
      </p>
      <p>
        <b>6.5 Nutzung von Google Remarketing<br /></b>Diese Webseite verwendet
        die Remarketing-Funktion der Google Ireland Limited, Gordon House,
        Barrow Street, Dublin 4, Irland („Google“). Die Funktion dient dazu,
        Webseitenbesuchern innerhalb des Google-Werbenetzwerks
        interessenbezogene Werbeanzeigen zu präsentieren. Im Browser des
        Webseitenbesuchers wird ein sog. „Cookie“ gespeichert, der es
        ermöglicht, den Besucher pseudonymisiert wiederzuerkennen, wenn dieser
        Webseiten aufruft, die dem Werbenetzwerk von Google angehören. Auf
        diesen Seiten können dem Besucher Werbeanzeigen präsentiert werden, die
        sich auf Inhalte beziehen, die der Besucher zuvor auf Webseiten
        aufgerufen hat, die die Remarketing Funktion von Google verwenden. Nach
        eigenen Angaben erhebt Google bei diesem Vorgang keine personenbezogenen
        Daten. Somit ergibt die Abwägung, dass keine überwiegenden Interessen
        Ihrerseits entgegenstehen (Art. 6 I S. 1 f DSGVO). Sollten Sie die
        Funktion Remarketing von Google dennoch nicht wünschen, können Sie diese
        grundsätzlich deaktivieren, indem Sie die entsprechenden Einstellungen
        unter http://www.google.com/settings/ads vornehmen. Alternativ können
        Sie den Einsatz von Cookies für interessenbezogene Werbung über die
        Werbenetzwerkinitiative deaktivieren, indem Sie den Anweisungen unter
        http://www.networkadvertising.org/managing/opt_out.asp folgen.
      </p>
      <p>
        <b>6.6 Nutzung von Google ReCAPTCHA<br /></b>Diese Website nutzt den
        Dienst reCAPTCHA des Unternehmens Google Ireland Limited, Gordon House,
        Barrow Street, Dublin 4, Irland („Google“). Die Funktion dient der
        Unterscheidung, ob die Eingabe durch einen Menschen oder missbräuchlich
        durch automatisierte, maschinelle Verarbeitung erfolgt. Die Abfrage
        schließt den Versand der IP-Adresse und ggf. weiterer von Google für den
        Dienst reCAPTCHA benötigter Daten an Google ein. Zu diesem Zweck wird
        Ihre Eingabe an Google übermittelt und dort weiterverwendet. Ihre
        IP-Adresse wird von Google jedoch innerhalb von Mitgliedstaaten der
        Europäischen Union oder in anderen Vertragsstaaten des Abkommens über
        den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen
        wird die volle IP-Adresse an einen Server von Google in den USA
        übertragen und dort gekürzt. Die im Rahmen von reCaptcha von Ihrem
        Browser übermittelte IP-Adresse wird nicht mit anderen Daten von Google
        zusammengeführt. Die Verarbeitung dient somit der Vermeidung des
        Missbrauchs dieser Website und findet letztendlich anonymisiert statt,
        somit ergibt die Abwägung, dass keine überwiegenden Interessen
        Ihrerseits einer Verarbeitung entgegenstehen (Art. 6 I S. 1 f DSGVO). Es
        geltend die Datenschutzrichtlinien von Google, diese finden Sie unter:
        https://policies.google.com/privacy?hl=de.
      </p>
      <p>
        <b>6.7 Google Web Fonts<br /></b>Wir nutzen sogenannte Web Fonts der
        Google Ireland Limited, Gordon House, Barrow Street, Dublin 4, Irland
        („Google“), um Ihnen eine einheitliche Schriftart auf unserer Website
        anzeigen zu können. Diese werden beim Aufruf einer unserer Seiten
        automatisch in Ihrem Browsercache gespeichert, um die gewünschte
        Darstellung zu ermöglichen. Sollte Ihr Browser die genutzten Web Fonts
        nicht unterstützen, wird ggf. eine Standardschrift Ihres Computers
        verwendet. Hier sind keine Interessen der Nutzer betroffen, welche diese
        technische Notwendigkeit überwiegen (Art. 6 I S. 1 f DSGVO). Die
        Datenschutzerklärung von Google können Sie hier einsehen:
        https://www.google.com/policies/privacy/ Weitere Informationen zu Google
        Web Fonts finden Sie unter https://developers.google.com/fonts/faq
      </p>
      <p>
        <b>6.8 Nutzung von Facebook Remarketing-Pixeln<br /></b>Wir nutzen die
        Remarketing-Funktion „Custom Audiences“ der Facebook Inc., 1 Hacker Way,
        Menlo Park, CA 94304, USAbetrieben wird (“Facebook”). Die für die
        Verarbeitung der von Facebook erhobenen Informationen zuständige Stelle
        ist Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour,
        Dublin 2, Irland. Diese Funktion ermöglicht uns die Besucher dieser
        Webseite bei Facebook zielgerichtet mit Werbung anzusprechen. Hierfür
        werden personalisierte und interessenbezogene Facebook-Ads geschaltet,
        wenn die Seiten von Facebook besucht werden. Die Funktion wird auf
        unserer Webseite durch ein Remarketing-Pixel von Facebook ermöglicht,
        welches auf der Seite implementiert wird. Über das Pixel wird bei einem
        Besuch unserer Webseite eine direkte Verbindung zum Facebook-Server
        hergestellt, wenn Sie dem Setzen des Pixel zugestimmt bzw. das Plugin
        aktiviert haben. An den Facebook-Server wird so übermittelt, welche
        unserer Internetseiten der Nutzer besucht hat. Facebook ordnet diese
        Information Ihrem persönlichen Facebook-Benutzerkonto zu. Nähere
        Informationen zur Erhebung und Nutzung der Daten durch Facebook, über
        Ihre diesbezüglichen Rechte und Möglichkeiten zum Schutz Ihrer
        Privatsphäre finden Sie in den Datenschutzhinweisen von Facebook unter
        https://www.facebook.com/about/privacy/ .
      </p>
      <p>
        Wenn Sie nicht möchten, dass Facebook die gesammelten Informationen
        unmittelbar Ihrem Facebook-Benutzerkonto zuordnet, können Sie die
        Remarketing-Funktion „Custom Audiences“ in Ihren Konoteinstellungen
        unter:
        https://www.facebook.com/login.php?next=https%3A%2F%2Fwww.facebook.com%2Fsettings%2F%3Ftab%3Dads#_=_bearbeiten.
        Hierzu müssen Sie bei Facebook angemeldet sein.
      </p>
      <p><b>6.9 Hotjar</b></p>
      <p>
        Wir benutzen auf unserer Website Hotjar, ein Analysesoftware der Hotjar
        Ltd. („Hotjar“) (http://www.hotjar.com, Level 2, St Julian’s Business
        Centre, 3, Elia Zammit Street, St Julian’s STJ 1000, Malta, Europe). Mit
        Hotjar haben wir die Möglichkeit Ihre Klicks, Mausbewegungen etc. auf
        unserer Website zu messen und auszuwerten. Die Informationen die dabei
        durch den „Tracking Code“ und „Cookie“ über Ihren Besuch auf unserer
        Website generiert werden, werden an die Hotjar Server in Irland
        übermittelt und dort gespeichert. Cookies von Hotjar können hierbei bis
        zu 365 Tagen gültig bleiben. Es werden folgenden Informationen
        gesammelt:
      </p>
      <p><b>Endgerätespezifische Daten</b></p>
      <p>
        Folgende Informationen werden in Bezug auf Ihr Endgerät und Ihrem
        Browser erhoben:
      </p>
      <p>
        IP-Adresse des Endgeräts (sie wird in anonymisierter Form erhoben und
        gespeichert);<br />Auflösung des Bildschirms/Displays des
        Endgerätebildschirms;<br />Endgerätetyp (individuelle
        Endgeräte-Identifizierungsmerkmale), Betriebssystem, und Browsertyp;<br />geografischer
        Standort (nur Land);<br />bei der Anzeige der Hotjar-basierten Webseite
        bevorzugte Sprache.<br />Nutzerinteraktionen
      </p>
      <p>
        Mausereignisse (Bewegungen, Position und Klicks);<br />Protokolldaten
      </p>
      <p>
        Stichprobenartig zeichnen die Server von Hotjar automatisch
        Informationen auf, die von unserer Website und der Website von Hotjar
        erhoben werden. U.a. werden folgende Daten erfasst:
      </p>
      <p>
        verweisende Domain;<br />besuchte Seiten;<br />geografischer Standort
        (nur Land);<br />bei der Anzeige der Webseite bevorzugte Sprache;<br />Datum
        und Zeit, wann auf die Seiten der Website zugegriffen wurde.<br />Diese
        Informationen nutzt Hotjar, um Ihre Nutzung unserer Website auszuwerten,
        Berichte über die Nutzung zu erstellen, sowie sonstige Leistungen, die
        die Websitebenutzung und Internetauswertung der Website betreffen.
        Hotjar bedient sich zudem der Leistungen von Dritten wie Google
        Analytics und Optimizely. Diese können Informationen, die Ihr Browser im
        Rahmen des Website-Besuches sendet (z.B. Cookies oder IP-Anfragen)
        speichern. Nähere Informationen können Sie der Datenschutzerklärung
        dieser Dienste entnehmen. Die Datenverarbeitung erfolgt auf Grundlage
        Ihrer Einwilligung (Art. 6 I S.1 a DSGVO). Sie können diese Einwilligung
        jederzeit widerrufen. Die Rechtmäßigkeit der bereits erfolgten
        Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. Wenn Sie eine
        Aufzeichnung nicht wünschen, können Sie diese durch das Setzen des
        DoNotTrack-Headers in Ihrem Browser deaktivieren. Sie können die
        Erfassung der Daten durch Hotjar zudem verhindern, indem Sie auf
        folgenden Link klicken und die dortigen Instruktionen befolgen:
        https://www.hotjar.com/opt-out. Die Datenschutzerklärung von Hotjar
        können Sie auf https://www.hotjar.com/privacy einsehen.
      </p>
      <p><b>6.10 Mixpanel</b></p>
      <p>
        Diese Website nutzt den Analysedienst Mixpanel (betrieben von der
        Mixpanel Inc., 405 Howard Street, Floor 2, San Francisco, CA 94105,
        USA). Dieser Dienst protokolliert Seitenaufrufe und Seitenaktivität.
        Hierzu werden Protokolldaten an Mixpanel mit einer Chiffre anonymisiert
        übertragen. Mixpanel ist unter dem EU-US Privacy Shield zertifiziert.
        Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 I
        S.1 a DSGVO).
      </p>
      <p>
        Nähere Informationen über die Verwendung Ihrer Daten können Sie in der
        Datenschutzerklärung von Mixpanel einsehen:
        https://mixpanel.com/legal/privacy-policy/
      </p>
      <p>
        Sie können die Speicherung der Cookies durch eine entsprechende
        Einstellung Ihrer Browser-Software verhindern; wir weisen Sie jedoch
        darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
        Funktionen dieser Website vollumfänglich werden nutzen können. Sie
        können die Erfassung Ihrer Daten durch Mixpanel darüber hinaus
        verhindern, indem Sie auf folgenden Link klicken. Es wird ein
        Opt-Out-Cookie gesetzt, der die Erfassung Ihrer Daten bei zukünftigen
        Besuchen dieser Website verhindert: Mixpanel deaktivieren.
      </p>
      <p><b>6.11 Drift</b></p>
      <p>
        Drift ist ein zusätzlicher Chat-Service der es Ihnen ermöglichen soll,
        sich umfassend über unseren Dienst zu informieren. Drift wird betrieben
        von der Drift.com Inc., 222 Berkeley Street, 6th Floor, Boston, MA
        02116, USA. Drift ist unter dem EU-US Privacy Shield zertifiziert. Die
        Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 I S.1
        a DSGVO). Drift verwendet für den Echtzeit-Chat u.a. “Cookies”, die auf
        Ihrem Computer gespeichert werden. Die durch den Cookie erzeugten
        Informationen werden an einen Server von Drift übertragen und dort
        gespeichert. Sie können die Speicherung der Cookies durch eine
        entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen
        Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
        sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.
        Die Datenschutzerklärung von Drift können Sie hier einsehen:
        https://www.drift.com/privacy-policy/
      </p>
      <p><b>6.12 Google Firebase</b></p>
      <p>
        Unsere Software liegt auf einem Server des Googles Dienstes Firbease
        (betrieben von der Google Ireland Limited, Gordon House, Barrow Street,
        Dublin 4, Irland). Bei Google Firebase handelt es sich um eine Plattform
        für Entwickler von Apps für mobile Geräte und Websites. Sofern Sie
        unseren Dienst nutzen, werden Ihre Daten daher notwendigerweise an
        Google gesendet und dort gespeichert. Die mittels von Google Firebase
        verarbeiteten Informationen werden ggf. zusammen mit weiteren Diensten
        von Google, wie z.B. Google Analytics und den Google-Marketing-Diensten
        verwendet. In diesem Fall werden nur pseudonyme Informationen
        verarbeitet, um Geräte der Nutzer zu identifizieren. Rechtsgrundlage für
        die Nutzung ist Art. 6 I S. 1 f DSGVO.
      </p>
      <p>
        Die Datenschutzerklärung von Google können Sie hier einsehen:
        https://www.google.com/policies/privacy
      </p>
      <p>
        Wenn Sie der interessensbezogenen Werbung durch Google-Marketing-Dienste
        widersprechen möchten, können Sie die von Google gestellten
        Einstellungs- und Opt-Out-Möglichkeiten nutzen:
        http://www.google.com/ads/preferences
      </p>
      <p><b>6.13 Pipedrive</b></p>
      <p>
        <span
          style="font-family: var( --e-global-typography-text-font-family ), Sans-serif; letter-spacing: -0.2px; display: inline !important;"
          >LobbySpace verwendet Pipedrive, um die Customer Relationship
          Management Aktivitäten zu verbessern.</span
        >
      </p>
      <p>Das verantwortliche Unternehmen ist:</p>
      <p>
        Pipedrive OÜ, Paldiski mnt 80, Tallinn 10617, Estland
      </p>
      <p>
        Jegliche Informationen, einschließlich personenbezogener Daten, die von
        einem anderen in dieser Datenschutzerklärung aufgeführten Dienste
        gesammelt werden, können an die Server von Pipedrive gesendet und dort
        gespeichert werden, wenn ein Benutzer eine Aktion durchführt, die zur
        Übermittlung von Informationen an testxchange führt.
      </p>
      <p>
        Weitere Informationen zur Datenverarbeitung und zum Datenschutz von
        Pipedrive finden Sie hier:
      </p>
      <p>https://www.pipedrive.com/en/privacy</p>
      <p>
        <span style="color: #333333;"><strong>6.14&nbsp;Mouseflow</strong></span
        ><br />Diese Website verwendet Mouseflow, ein Webanalyse-Tool der
        Mouseflow ApS, Flaesketorvet 68, 1711 Kopenhagen, Dänemark, um zufällig
        ausgewählte einzelne Besuche (nur mit anonymisierter IP-Adresse)
        aufzuzeichnen. So entsteht ein Protokoll der Mausbewegungen und Klicks
        mit der Absicht einzelne Website-Besuche stichprobenartig abzuspielen
        und potentielle Verbesserungen für die Website daraus abzuleiten. Die
        Informationen sind nicht personenbezogen und werden nicht weitergegeben.
        Wenn Sie eine Aufzeichnung nicht wünschen, können Sie diese auf allen
        Websites die Mouseflow einsetzen, unter dem folgenden Link deaktivieren:
        www.mouseflow.de/opt-out/.
      </p>
      <h5>6.15&nbsp;Leadfeeder</h5>
      <p>
        Wir nutzen den Dienst LeadFeeder als Lead Generierungs Tool. LeadFeeder
        greift auf die Liste der von Google Analytics in der Auswertung
        bereitgestellten IP-Adressen der Internetseitenbesucher zu und verlinkt
        die Liste der IP-Adressen mit Informationen über die Firmen, die im
        Internet unter diesen IP-Adressen zu finden sind. Aufgrund der schon bei
        der Nutzung von Google Analytics durchgeführten Kürzung der IP-Adresse
        der Internetseitenbesucher wird ein direkter Personenbezug nicht
        hergestellt.
      </p>
      <p>
        Es lassen sich lediglich Firmenbesucher vermuten. Leadfeeder ist in
        unserem CRM System &amp; E-Mail Marketing Tool integriert. LeadFeeder
        ist ein Dienst der Liidio Oy, Mikonkatu 17 C, Helsinki 00100, Finland.
      </p>
      <p>
        Die Datenschutzerklärung von Leadfeeder findet man unter
        https://www.leadfeeder.com/privacy/
      </p>
      <p>
        Sie können der Speicherung eines Benutzerprofils oder von Daten über
        Ihre Nutzung unserer Seite durch Leadfeeder mittels eines «opt-out»
        verhindern. Die Möglichkeit und Informationen hierzu finden Sie unter:
      </p>
      <p>https://yourdata.leadfeeder.com/</p>
      <p>
        Wenn Sie im Cookie-Banner dieser Website &#8211; lobbyspace.me &#8211;
      </p>
      <p>
        die Checkbox „Leadfeeder“ auswählen und auf „Auswahl akzeptieren“
        klicken oder<br />auf „Alle akzeptieren“ klicken,<br />geben Sie Ihre
        Einwilligung nach Art. 6 Abs. 1 S. 1 lit. a i.V.m. Art. 49 Abs. 1 a)
        DS-GVO, dass Ihre personenbezogenen Daten in den USA verarbeitet werden
        dürfen.
      </p>
      <p>
        <b>Kontaktaufnahme</b><br />Zur Kontaktaufnahme bezüglich des
        Datenschutzes können Sie sich gern an uns unter Verwendung der
        nachfolgenden Kontaktmöglichkeiten wenden. Verantwortlicher im Sinne der
        DSGVO:
      </p>
      <p>LobbySpace GmbH</p>
      <p>
        <span
          style="font-family: var( --e-global-typography-text-font-family ), Sans-serif; letter-spacing: -0.2px; display: inline !important;"
          >Ballindamm 3</span
        >
      </p>
      <p>
        <span
          style="font-family: var( --e-global-typography-text-font-family ), Sans-serif; letter-spacing: -0.2px; display: inline !important;"
          >20095 Hamburg</span
        >
      </p>
      <p>E-Mail: hello@lobbyspace.me<br />Telefon: 040 22858947</p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.data-privacy {
  padding: 32px;
  max-width: 1000px;
  margin: 0 auto;

  &__title {
    @apply mb-4 text-4xl font-semibold text-center;
  }

  &__content {
  }

  &__left,
  &__right {
    width: 50%;
    padding: 24px;
  }

  p {
    @apply mb-4;
  }
}
</style>
